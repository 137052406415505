<template>
  <div>
    <div class="main">
      <div class="search-area">
        <div class="form-area">
          <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" class="rule-form">
            <el-form-item prop="staff_name">
              <el-input size="small" v-model="ruleForm.staff_name" placeholder="输入姓名关键字搜索"></el-input>
            </el-form-item>
            <el-form-item prop="type">
              <el-select size="small" v-model="ruleForm.type" placeholder="出勤类型">
                <el-option label="请假" value="-1"></el-option>
                <el-option label="加班" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="name">
              <el-select size="small" v-model="ruleForm.name" placeholder="申请类型">
                <el-option label="调休" value="调休"></el-option>
                <el-option label="事假" value="事假"></el-option>
                <el-option label="病假" value="病假"></el-option>
                <el-option label="年假" value="年假"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div class="rule-form__btn">
                <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">搜索</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="apply-btn">
          <el-button type="danger" size="small" @click="dialogFormVisible = true">出勤申请</el-button>
        </div>
      </div>
      <div class="table-content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          border
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            :index="indexMethod"
            width="60">
          </el-table-column>
          <el-table-column
            prop="staff.name"
            label="姓名"
            min-width="100">
          </el-table-column>
          <el-table-column
            label="出勤类型"
            min-width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.type === -1">请假</div>
              <div v-else-if="scope.row.type === 1">加班</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="申请类型"
            min-width="100">
          </el-table-column>
          <el-table-column
            prop="begin_time"
            label="开始时间"
            min-width="180">
          </el-table-column>
          <el-table-column
            prop="end_time"
            label="结束时间"
            min-width="180">
          </el-table-column>
          <el-table-column
            prop="time_hour"
            label="时长"
            min-width="100">
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            min-width="150">
          </el-table-column>
          <el-table-column
            label="操作"
            min-width="180">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="table-pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :hide-on-single-page="total < 15"
          :page-sizes="[15]"
          :page-size="15"
          background
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
      <el-dialog title="出勤申请" center custom-class="apply-dialog" :visible.sync="dialogFormVisible">
        <el-form label-position="right" :label-width="formLabelWidth" :model="form">
          <el-form-item label="选择员工：">
            <el-select v-model="form.staff_id" placeholder="选择员工">
              <el-option :label="item.name" :value="item.id" v-for="item in staffList" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请类型：">
            <el-select v-model="form.type" placeholder="出勤类型">
              <el-option label="请假" value="-1"></el-option>
              <el-option label="加班" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请方式：">
            <el-select v-model="form.name" placeholder="申请类型">
              <el-option label="调休" value="调休"></el-option>
              <el-option label="事假" value="事假"></el-option>
              <el-option label="病假" value="病假"></el-option>
              <el-option label="年假" value="年假"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间：">
            <el-date-picker
              v-model="form.begin_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="开始时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：">
            <el-date-picker
              v-model="form.end_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="总用天数：">
            <el-input v-model="form.time_day" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="总用时长：">
            <el-input v-model="form.time_hour" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="其他备注：">
            <el-input v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleApply">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        staff_name: '',
        type: '',
        name: ''
      },
      rules: {
        staff_name: [
          { required: false, message: '请输入关键字', trigger: 'blur' }
        ],
        type: [
          { required: false, message: '请选择出勤类型', trigger: 'change' }
        ],
        name: [
          { required: false, message: '请选择申请类型', trigger: 'change' }
        ]
      },
      tableData: [],
      total: 0,
      currentPage: 1, // 当前页
      formLabelWidth: '120px',
      dialogFormVisible: false,
      form: {
        staff_id: '',
        type: '',
        name: '',
        begin_time: '',
        end_time: '',
        time_day: '',
        time_hour: '',
        remark: '',
      },
      staffList: [], // 工作人员列表
    }
  },
  methods: {
    /**
     * 提交搜索
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    /**
     * 重置
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    indexMethod(index) {
      return ('00' + (index + 1)).substr(0, 3);
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          href: "/api/app/meiye/attendance/del",
          data: {
            id: row.id
          },
          loading: true,
        }).then((res) => {
          console.log('deleteRes', res);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        }).catch((err) => {
          this.$message({
            type: 'error',
            message: err.data.message
          });
        });
      }).catch(() => {
        console.log('取消删除');    
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.thisPage = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    /**
     * 获取工作人员
     */
    getStaff() {
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
        loading: true,
      }).then((res) => {
        console.log('工作人员列表：', res);
        this.staffList = res.data;
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: err.data.message
        });
      });
    },
    /**
     * 获取请假列表
     */
    getList() {
      this.$axios({
        href: "/api/app/meiye/attendance/store",
        data: {
          staff_name: this.ruleForm.staff_name,
          type: this.ruleForm.type,
          name: this.ruleForm.name,
          page: this.currentPage
        },
      }).then((res) => {
        console.log('getListRes', res);
        this.tableData = res.data.data.items;
        this.total = res.data.data.total;
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: err.data.message
        });
      });
    },
    /**
     * 提交出勤申请
     */
    handleApply() {
      this.$axios({
        href: "/api/app/meiye/attendance/add",
        data: {
          staff_id: this.form.staff_id,
          type: this.form.type,
          name: this.form.name,
          begin_time: this.form.begin_time,
          end_time: this.form.end_time,
          time_day: this.form.time_day,
          time_hour: this.form.time_hour,
          remark: this.form.remark,
        },
      }).then((res) => {
        console.log('applyRes', res);
        if (res.data.successful === 1) {
          this.dialogFormVisible = false;
          this.form = {
            staff_id: '',
            type: '',
            name: '',
            begin_time: '',
            end_time: '',
            time_day: '',
            time_hour: '',
            remark: '',
          };
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
          this.currentPage = 1;
          this.getList();
        }
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: err.data.message
        });
      });
    },
  },
  created() {
    this.getStaff();
    this.getList();
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  .search-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    .rule-form {
      display: flex;
      align-items: center;
      padding-top: 2px;
      .el-form-item {
        margin-bottom: 4px;
      }
      &__btn {
        display: flex;
      }
    }
  }
  .table-content {
    width: 100%;
    margin-top: 10px;
  }
  .apply-dialog {
    .el-form {
      width: 440px;
      margin: 0 auto;
      &-item {
        width: 440px;
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
</style>
